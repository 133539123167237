import React, { FC, useState, useEffect } from 'react';

import { HIDDEN_USER } from '@common/constants/user/HIDDEN_USER';
import { useIsMe } from '@common/hooks/useIsMe';
import { UserType } from '@legacyApp/types/user/UserType';
import { HiddenData } from '@ui/hidden-data';
import { UserName, UserNameOptions } from '@ui/user-name';

import { useMe, useUser } from '@common/components/user/UserLogin/useUser';

export interface UserLoginProps extends UserNameOptions {
	user?: UserType;
}

const UserName_: FC<UserLoginProps> = (props) => {
	const userNameProps = useUser(props);

	return <UserName {...userNameProps} />;
};

const UserMe_: FC<UserLoginProps> = (props) => {
	const userNameProps = useMe(props);

	return <UserName {...userNameProps} />;
};

export const UserLogin: FC<UserLoginProps> = (props) => {
	const isMe = useIsMe(props?.user?.uuid);

	const [finalUser, setFinalUser] = useState<UserType | null>(null);

	useEffect(() => {
		const timer = setTimeout(() => {
			setFinalUser(props.user);
		}, 1000);

		return () => clearTimeout(timer);
	}, [props.user]);

	if (!finalUser) {
		return <div>Loading...</div>;
	}

	if (finalUser.login === HIDDEN_USER) {
		return <HiddenData />;
	}

	if (isMe) {
		return <UserMe_ {...props} />;
	}

	return <UserName_ {...props} />;
};
