import React, {
	FC,
	Fragment,
	HTMLAttributes,
	useEffect,
	useRef,
	useState,
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classnames from 'classnames';
import {
	faCheckCircle,
	faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import { Copy } from '@common/icons/ui/copy';
import { isFunction } from '@common/methods/isFunction';
import { isTrueOrZero } from '@common/methods/isTrueOrZero';
import { preventDefault } from '@common/methods/preventDefault';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { getId } from '@legacyApp/client/modules/app/appService';
import { usePrevious } from '@legacyApp/hooks/render/usePrevious';

import { AwesomeIcon } from '@common/components/icons/AwesomeIcon';
import { Input } from '@legacyApp/client/components/input/input';
import { InputLabelStyle } from '@legacyApp/client/components/input/styled/inputLabelStyle';
import { InputRelativeBoxStyle } from '@legacyApp/client/components/input/styled/inputRelativeBox.styled';
import { InputWrapperStyle } from '@legacyApp/client/components/input/styled/inputWrapper.style';
import { Tooltip } from '@legacyApp/client/components/tooltip';
import { Button } from '@ui/button';

export type InterfaceSpread<L, R> = R & Pick<L, Exclude<keyof L, keyof R>>;

interface InputWrapperPropsExtra {
	maxLength?: any;
	onChange?: Function;
}

export interface InputWrapperProps
	extends InterfaceSpread<
		HTMLAttributes<HTMLDivElement>,
		InputWrapperPropsExtra
	> {
	value: any;
	onChangeDelayed?: Function;
	timeout?: any;
	precision?: any;
	label?: any;
	labelClasses?: string;
	message?: any;
	isDisabled?: any;
	messageTheme?: string;
	isCopy?: boolean;
	inputButtons?: any;
	type?: any;
	name: any;
	wrapperClasses?: any;
	translateValue?: boolean;
	childrenClasses?: any;
	inputClasses?: any;
	showAlert?: Function;
	inputButtonsClasses?: string;
	sideLabel?: any;
	light?: boolean;
	onFocusOut?: Function;
	messageHideOnClick?: boolean;
	noChildrenWrapper?: boolean;
	localTheme?: any;
	maxAmount?: any;
	themeInput?: any;
	noTranslatePlaceholder?: boolean;
	step?: number;
	isChildrenBottom?: boolean;
	onModal?: boolean;
	readOnly?: boolean;
	componentName?: string;
}

export const InputWrapper: FC<InputWrapperProps> = ({
	value,
	onChange,
	onChangeDelayed,
	timeout,
	precision,
	maxLength,
	label,
	labelClasses,
	message,
	isDisabled,
	messageTheme,
	isCopy,
	placeholder,
	inputButtons,
	type,
	name,
	children,
	wrapperClasses,
	translateValue,
	childrenClasses,
	inputClasses,
	showAlert,
	inputButtonsClasses,
	sideLabel,
	light,
	onFocusOut,
	messageHideOnClick,
	noChildrenWrapper,
	localTheme,
	maxAmount,
	themeInput,
	noTranslatePlaceholder,
	step,
	isChildrenBottom,
	onClick,
	readOnly,
	onModal,
	componentName,
}) => {
	const [id] = useState<string>(`${name || ''}-${getId()}`);

	const wrapper = useRef<HTMLDivElement>(null);

	const prevMessage = usePrevious(message);

	// const mapDispatchToProps = (dispatch) => {
	// 	return {
	// 		showAlert: (type, message) => dispatch(showAlert(type, message)),
	// 	};
	// };
	// 	const validate = useDispatchCallback(validateClientSeedThunk);

	useEffect(() => {
		if (!!message !== !!prevMessage && wrapper.current) {
			const input = wrapper.current?.querySelector('input');

			if (input) {
				input.focus();
			}
		}
	}, [message, prevMessage]);

	const handleCopied = (value) => {
		showAlert(
			'success',
			trans({
				label: '{{value}} - Copied!',
				options: {
					value,
				},
			}),
		);
	};

	const setMaxAmount = () => {
		isFunction(maxAmount) ? maxAmount() : onChange(maxAmount);
	};

	const inputButtonsArray = inputButtons
		? inputButtons.length
			? inputButtons
			: [inputButtons]
		: [];

	const childrenWrapper =
		children &&
		(noChildrenWrapper ? (
			children
		) : (
			<div
				className={classnames('input-children', {
					['input-children-bottom']: isChildrenBottom,
					[childrenClasses]: childrenClasses,
				})}
			>
				{children}
			</div>
		));

	return (
		<InputWrapperStyle
			ref={wrapper}
			localTheme={localTheme}
			maxAmount={isTrueOrZero(maxAmount)}
			className={
				name === 'address' ? `${wrapperClasses} !mb-0` : wrapperClasses
			}
		>
			{label && (
				<InputLabelStyle
					htmlFor={id}
					onClick={preventDefault}
					className={labelClasses}
				>
					{typeof label === 'string' ? trans({ label: label }) : label}
				</InputLabelStyle>
			)}
			{componentName === 'PersonalDataForm' ? (
				<InputRelativeBoxStyle
					data-sidelabel={sideLabel}
					isSideLabel={sideLabel}
					componentName={componentName}
				>
					<Input
						id={id}
						onChange={onChange}
						onFocusOut={onFocusOut}
						onChangeDelayed={onChangeDelayed}
						onClick={onClick}
						timeout={timeout}
						precision={precision}
						maxLength={maxLength}
						inputClasses={inputClasses}
						value={value}
						placeholder={placeholder}
						noTranslatePlaceholder={noTranslatePlaceholder}
						type={type}
						name={name}
						light={light}
						translateValue={translateValue}
						localTheme={themeInput}
						isDisabled={!!isDisabled}
						step={step}
						readOnly={readOnly}
					/>
					{messageTheme === 'error' && (
						<div className="box-no-height text-negative no-wrap text-style-xs-regular">
							{trans({
								label: message,
							})}
						</div>
					)}
					{(isCopy || !!inputButtonsArray?.length) && (
						<div className={classnames('buttons-box', inputButtonsClasses)}>
							{isCopy && (
								<CopyToClipboard text={value} onCopy={handleCopied}>
									<Button
										name="input-copy-to-clipboard"
										iconBefore={<Copy />}
									/>
								</CopyToClipboard>
							)}
							{inputButtonsArray.map((el, index) => (
								<Fragment key={index}>{el}</Fragment>
							))}
						</div>
					)}
					{isChildrenBottom ? null : childrenWrapper}
					{isTrueOrZero(maxAmount) && (
						<Button
							name="input-max"
							className="text-uppercase"
							onClick={setMaxAmount}
							label={trans({ label: 'max' })}
						/>
					)}
					{!!sideLabel && (
						<span className="input-side-label">
							{sideLabel === 'error' ? (
								<AwesomeIcon icon={faTimesCircle} className="text-negative" />
							) : (
								sideLabel === 'correct' && (
									<AwesomeIcon icon={faCheckCircle} className="text-positive" />
								)
							)}
						</span>
					)}
				</InputRelativeBoxStyle>
			) : (
				<Tooltip
					localTheme={messageTheme || 'default'}
					onModal={onModal}
					content={message}
					hideOnClick={messageHideOnClick}
					isVisible={!!message}
				>
					<InputRelativeBoxStyle
						data-sidelabel={sideLabel}
						isSideLabel={sideLabel}
					>
						<Input
							id={id}
							onChange={onChange}
							onFocusOut={onFocusOut}
							onChangeDelayed={onChangeDelayed}
							onClick={onClick}
							timeout={timeout}
							precision={precision}
							maxLength={maxLength}
							inputClasses={inputClasses}
							value={value}
							placeholder={placeholder}
							noTranslatePlaceholder={noTranslatePlaceholder}
							type={type}
							name={name}
							light={light}
							translateValue={translateValue}
							localTheme={themeInput}
							isDisabled={!!isDisabled}
							step={step}
							readOnly={readOnly}
						/>
						{messageTheme === 'error' && (
							<div className="box-no-height text-negative no-wrap text-style-xs-regular">
								{trans({
									label: message,
								})}
							</div>
						)}
						{(isCopy || !!inputButtonsArray?.length) && (
							<div className={classnames('buttons-box', inputButtonsClasses)}>
								{isCopy && (
									<CopyToClipboard text={value} onCopy={handleCopied}>
										<Button
											name="input-copy-to-clipboard"
											iconBefore={<Copy />}
										/>
									</CopyToClipboard>
								)}
								{inputButtonsArray.map((el, index) => (
									<Fragment key={index}>{el}</Fragment>
								))}
							</div>
						)}
						{isChildrenBottom ? null : childrenWrapper}
						{isTrueOrZero(maxAmount) && (
							<Button
								name="input-max"
								className="text-uppercase"
								onClick={setMaxAmount}
								label={trans({ label: 'max' })}
							/>
						)}
						{!!sideLabel && (
							<span className="input-side-label">
								{sideLabel === 'error' ? (
									<AwesomeIcon icon={faTimesCircle} className="text-negative" />
								) : (
									sideLabel === 'correct' && (
										<AwesomeIcon
											icon={faCheckCircle}
											className="text-positive"
										/>
									)
								)}
							</span>
						)}
					</InputRelativeBoxStyle>
				</Tooltip>
			)}
			{isChildrenBottom ? childrenWrapper : null}
		</InputWrapperStyle>
	);
};
